import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31a59e17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container desktop-width-sm ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stripe_pricing_table = _resolveComponent("stripe-pricing-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_stripe_pricing_table, {
      "customer-email": _ctx.authStore.user.email,
      "pricing-table-id": _ctx.pricingTableId,
      "publishable-key": _ctx.publishableKey,
      class: "ion-text-center"
    }, null, 8, ["customer-email", "pricing-table-id", "publishable-key"])
  ]))
}